<template>
  <div  align="center">
    <b-card style="max-width: 360px">
      <b-card-title>
        <span class="font-weight-normal">{{ sourceTest.SourceName }}</span>
        <hr/>
        <span class="font-weight-normal">{{ sourceTest.SourceChapterName }}</span> - <span class="font-weight-normal">{{ sourceTest.Name }}</span>
        <hr/>
        ÖDEV CEVAPLARI
        <a v-show="!hwId" href="/odevler" class="btn btn-light-primary btn-sm"> Ödev Listesi </a>
      </b-card-title>
      <div v-for="(item, index) in homeworkAnswers" :key="index">
        <div class="mt-3 d-flex">
          <div class="text-center mt-2">{{ item.QuestionNumber.toString() }})</div>
          <div class="text-center flex-grow-1">
            <b-form-radio-group
                v-model="item.TestOptionTypeId"
                :options="testOptionTypes"
                :button-variant="item.color ? item.color : 'outline-secondary'"
                size="md"
                name="radio-btn-outline"
                buttons
                @change="saveTestAnswerToList(item)"
                value-field="Id"
                text-field="Name"
            ></b-form-radio-group>
          </div>
        </div>
      </div>
      <b-progress
          v-b-popover.hover="correctCount + ' D / ' + wrongCount + ' Y / ' + emptyCount + ' B'"
          class="mt-3"
          :max="progressMax"
          show-value
      >
        <b-progress-bar :value="correctCount" variant="success"></b-progress-bar>
        <b-progress-bar :value="wrongCount" variant="danger"></b-progress-bar>
        <b-progress-bar :value="emptyCount" variant="secondary"></b-progress-bar>
      </b-progress>
      <b-button variant="primary" class="mt-3" @click="saveTestAnswersBulk">KAYDET</b-button>
    </b-card>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import Swal from 'sweetalert2'

export default {
  name: 'odev-cevaplari',
  props: ['testId', 'hwId'],
  data() {
    return {
      answerCount: 0,
      testOptionTypes: [],
      homeworkAnswers: [],
      sourceTest: {},
      answersBulk: [],
      sourceTestAnswers: [],
      sourceTestId: this.$route.params.sourceTestId,
      homeworkId: this.$route.params.homeworkId
    }
  },
  computed: {
    progressMax() {
      return this.homeworkAnswers.length
    },
    correctCount() {
      return this.homeworkAnswers.filter((s) => s.color == 'outline-success').length
    },
    wrongCount() {
      return this.homeworkAnswers.filter((s) => s.color == 'outline-danger').length
    },
    emptyCount() {
      return this.homeworkAnswers.filter((s) => s.color == null || s.color.length == 0).length
    }
  },
  created() {
    this.getTestOptionTypesFromApi()
  },
  mounted() {
      if (this.hwId > 0)
          this.homeworkId = this.hwId
      if (this.testId > 0)
          this.sourceTestId = this.testId
      
    this.getAnswers()
    this.getSourceTestAnswersFromApi()
  },
  methods: {
    getTestOptionTypesFromApi() {
      ApiService.setHeader()
      ApiService.post('api/TestOptionType', {
        sortBy: ['DisplayOrder'],
        descending: [false],
        rowsPerPage: -1,
        page: 1
      })
          .then((data) => {
            this.testOptionTypes = data.data.Results
            this.testOptionTypes.push({
              DisplayOrder: 99,
              Name: 'Boş',
              Id: 0
            })
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    async getSourceTestAnswersFromApi() {
      ApiService.setHeader()
      try {
        let data = await ApiService.get('api/SourceTest/' + this.sourceTestId)
        this.sourceTest = data.data
        this.answerCount = data.data.QuestionCount
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
      try {
        ApiService.setHeader()
        let data2 = await ApiService.post('api/HomeworkAnswer', {
          sortBy: ['QuestionNumber'],
          descending: [false],
          rowsPerPage: -1,
          page: 1,
          query: {
            SourceTestId: this.sourceTestId,
            HomeworkId: this.homeworkId
          }
        })

        this.homeworkAnswers = data2.data.Results
        for (let index = 1; index <= this.answerCount; index++) {
          if (this.homeworkAnswers.filter((s) => s.QuestionNumber == index).length == 0) {
            var element = {
              QuestionNumber: index,
              HomeworkId: this.homeworkId,
              Id: 0,
              SourceTestId: this.sourceTestId
            }
            this.homeworkAnswers.push(element)
            this.homeworkAnswers = this.homeworkAnswers.sort(function (a, b) {
              return a.QuestionNumber - b.QuestionNumber
            })
          } else if (this.sourceTestAnswers.length > 0) {
            var isCorrectAnswer =
                this.sourceTestAnswers.filter(
                    (s) =>
                        s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber &&
                        s.TestOptionTypeIds.includes(this.homeworkAnswers[index - 1].TestOptionTypeId)
                ).length == 1
            if (isCorrectAnswer) {
              this.homeworkAnswers.filter(
                  (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
              )[0].color = 'outline-success'
            } else {
              this.homeworkAnswers.filter(
                  (s) => s.QuestionNumber == this.homeworkAnswers[index - 1].QuestionNumber
              )[0].color = 'outline-danger'
            }
          }
        }
      } catch (response) {
        ApiService.showError(response.response)
        return
      }
    },

    saveTestAnswerToList(item) {
      for (var i = 0; i < this.answersBulk.length; i++) {
        if (this.answersBulk[i].QuestionNumber == item.QuestionNumber) {
          this.answersBulk.splice(i, 1)
          break
        }
      }
      this.answersBulk.push(item)
    },

    saveTestAnswersBulk() {
      this.calcResults()
      ApiService.setHeader()
      ApiService.put('api/HomeworkAnswer/upsert-bulk', {
        Answers: this.answersBulk,
        HomeworkId: this.homeworkId,
        SourceTestId: this.sourceTestId
      })
          .then(() => {
            Swal.fire({
              toast: true,
              position: 'bottom',
              icon: 'success',
              title: 'Değişiklikler Uygulandı',
              showConfirmButton: false,
              timer: 1500
            })
            this.answersBulk = []
            this.getSourceTestAnswersFromApi()
          })
          .catch(({ response }) => {
            ApiService.showError(response)
          })
    },

    getAnswers() {
      ApiService.setHeader()
      ApiService.post('api/SourceTestAnswer', {
        sortBy: ['QuestionNumber'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {SourceTestId: this.sourceTestId}
      })
          .then((data) => {
            this.sourceTestAnswers = data.data.Results
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    calcResults() {
      if (this.answersBulk.length > 0) {
        var model = { AnswerList: [] }

        model.AnswerList = this.homeworkAnswers.map(function (s) {
          return {
            Id: s.Id,
            HomeworkId: s.HomeworkId,
            SourceTestId: s.SourceTestId,
            QuestionNumber: s.QuestionNumber,
            TestOptionTypeId: s.TestOptionTypeId
          }
        })

        this.answersBulk = this.answersBulk.map(function (s) {
          return {
            Id: s.Id,
            HomeworkId: s.HomeworkId,
            SourceTestId: s.SourceTestId,
            QuestionNumber: s.QuestionNumber,
            TestOptionTypeId: s.TestOptionTypeId
          }
        })

        for (var i = 0, l = model.AnswerList.length; i < l; i++) {
          for (var j = 0, ll = this.answersBulk.length; j < ll; j++) {
            if (model.AnswerList[i].QuestionNumber === this.answersBulk[j].QuestionNumber) {
              model.AnswerList.splice(i, 1, this.answersBulk[j])
              break
            }
          }
        }
        ApiService.setHeader()
        ApiService.post('api/HomeworkAnswer/calculateresult', model)
            .catch(({ response }) => {
              ApiService.showError(response)
            })
      }
    }
  }
}
</script>
